<template>
  <div class="modalContainer noselect" @click="closeModal">

    <div v-if="tab==='film'" class="filmModalBody" @click="stopCloseModal">
      <div class="detailsContainer">
        <font-awesome-icon icon="times" class="closeIcon" @click="closeModal" />
        <div class="projectTitle">{{project.title}}</div>
        <div class="projectSubtitle">{{project.subtitle}}</div>
        <div class="projectBody">{{project.body}}</div>
      </div>
      <div :class="project.size">
        <ContainerAnimated :pose="isVisible ? 'visible' : 'hidden'">
        <iframe
            class="videoElement"
            :src="project.link"
            frameborder="0"
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture; fullscreen"
          />
        </ContainerAnimated>
      </div>
    </div>

    <div v-if="tab==='photos'" class="photosModalBody">
      <font-awesome-icon icon="times" class="closeIcon" @click="closeModal" />
      <div class="projectTitle">{{project.title}}</div>
      <ContainerAnimated :pose="isVisible ? 'visible' : 'hidden'">
        <img :src="path" :alt="project.title" class="photoElement" @click="stopCloseModal"/>
      </ContainerAnimated>
    </div>

    <div v-if="tab==='furniture'" class="furnitureModalBody">
      <font-awesome-icon icon="times" class="closeIcon" @click="closeModal" />
      <div class="furnitureImagesContainer">
        <div class='furnitureBeforeImageContainer'>
          <div class="beforeAfterLabelText">before</div>
          <ContainerAnimated :pose="isVisible ? 'visible' : 'hidden'">
            <img :src="beforePath" :id="project.beforePath" alt="before image" class="beforePhoto" @click="stopCloseModal"/>
          </ContainerAnimated>
        </div>
        <div class='furnitureAfterImageContainer'>
          <div class="beforeAfterLabelText">after</div>
           <ContainerAnimated :pose="isVisible ? 'visible' : 'hidden'">
            <img :src="afterPath" alt="after image" class="afterPhoto" @click="stopCloseModal"/>
          </ContainerAnimated>
        </div>
      </div>
    </div>

   <div v-if="tab === 'misc'" class="miscModalBody" @click="stopCloseModal">
      <font-awesome-icon icon="times" class="closeIcon" @click="closeModal" />
      <div class="markdownContainer" v-html="project.content"></div>
    </div>

    <div :class="tab === 'misc' ? 'hideArrows' : 'arrows'" @click="stopCloseModal">
      <font-awesome-icon  icon="arrow-left" :class="'prevIcon ' + (showPrev ? '' : 'disabled')" :disabled="!showPrev" @click="showPrevInner" />
      <font-awesome-icon icon="arrow-right" :class="'nextIcon ' + (showNext ? '' : 'disabled')" @click="showNextInner" />
    </div>

  </div>
</template>

<script>

import posed from 'vue-pose';

export default {
  name: 'Modal',
  props: ['project', 'tab', 'showNext', 'showPrev', 'index'],
  components: {
    ContainerAnimated: posed.div({
      visible: { transition: { duration: 250 }, opacity: 1},
      hidden: { opacity: 0},
    })
  },
  beforeMount() {
    console.log('beforeMount')
    this.path = this.tab === 'photos' ? require(`/src/assets/photos/full/${this.project.path}`) : null
    if (this.tab === 'furniture') {
      this.beforePath = require(`/src/assets/furniture/full/${this.project.beforePath}`)
      this.afterPath = require(`/src/assets/furniture/full/${this.project.afterPath}`)
    }
    window.addEventListener('keydown', this.handleKeyPress)
  },
  mounted() {
    setTimeout(() => {
      this.isVisible = true;
    }, 0);
  },
  updated(){
    console.log('updated')
    this.path = this.tab === 'photos' ? require(`/src/assets/photos/full/${this.project.path}`) : null
    if (this.tab === 'furniture') {
      this.beforePath = require(`/src/assets/furniture/full/${this.project.beforePath}`)
      this.afterPath = require(`/src/assets/furniture/full/${this.project.afterPath}`)
    }
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyPress);
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
    stopCloseModal(e) {
      e.stopPropagation()
    },
    showNextInner(e) {
      e.stopPropagation()
      this.showNext()
    },
    showPrevInner(e) {
      e.stopPropagation()
      this.showPrev()
    },
    handleKeyPress(e) {
      if (e.key == 'Escape') {
        this.closeModal()
      }
      if (e.key === 'ArrowLeft') {
        this.showPrev()
      }
      if (e.key === 'ArrowRight') {
        this.showNext()
      }
    }
  },
  data() {
    return {
      showDetails: false,
      path: null,
      beforePath: null,
      afterPath: null,
      isVisible: false
    }
  }
}
</script>

<style scoped>
  .modalContainer {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.623);
    z-index: 100;
    top: 0;
    left: 0;
    backdrop-filter: blur(50px);
    overflow-y: auto;
  }

  .miscModalBody {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px;
    z-index: 105;
    justify-content: flex-start;
    align-items: center;
  }

  .markdownContainer {
    position: fixed;
    width: 90%;
    height: 90%;
    max-width: 900px;
    margin-top: 20px;
    /* padding: 0 30px 10px 30px; */
    padding: 0 30px;
    color: white;
    background: var(--light-background);
    font-size: 1.3rem;
    border-radius: 5px;
    /* font-size: 120%;
    line-height: 1.2; */
    overflow-y: scroll;
  }

  .markdownContainer img {
    max-width: 100%;
    max-height: 80vh;
    margin: 20px 0;
    border: 2px solid red;
  }

  .filmModalBody {
    position: fixed;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    z-index: 105;
  }

  .photosModalBody {
    position: fixed;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px;
    z-index: 105;
  }

  .furnitureModalBody {
    position: fixed;
    width: 100%;
    display: flex;
    padding: 30px;
    z-index: 105;
    justify-content: center;
  }

  .furnitureImagesContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
  }

  .furnitureBeforeImageContainer {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin: 0 10px;
  }

  .furnitureAfterImageContainer {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin: 0 10px;
  }

  .beforePhoto, .afterPhoto {
    margin-top: 5px;
    object-fit: contain;
    width:100%;
    border: 2px solid black;
    margin-bottom: 15px;
    max-height: 40vh;
  }

  .beforeAfterLabelText {
    color: var(--white-text);
    font-size: 2rem;
  }

  .projectTitle {
    color: var(--white-text);
    font-size: 2rem;
    margin-right: 30px;
  }

  .black {
    color: black;
  }

  .projectSubtitle {
    color: var(--accent-green);
    font-style: italic;
    font-weight: 400;
    font-size: 1.3rem;
    margin-top: 5px;
  }

  .projectBody {
    color: var(--white-text);
    margin: 20px 0 0 0;
    font-size: 1rem;
    line-height: 1.3;
  }

  .detailsContainer {
    background: var(--light-background);
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
    width: 100%;
  }

  .normal {
    width: 100%;
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
  }

  .widescreen {
    width: 100%;
    position: relative;
    padding-bottom: 42.61%;
    height: 0;
  }

  .videoElement {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid black;
  }

  .photoElement {
    margin-top: 20px;
    object-fit: contain;
    max-width: 100%;
    border: 2px solid black;
  }

  .closeIcon {
    color: var(--accent-green);
    font-size: 130%;
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 115;
    cursor: pointer;
  }

  .arrows {
    margin-top: 20px;
    min-height: 50px;
    position: fixed;
    z-index: 110;
    top: 83vh;
    left: calc(50vw - 38px);
    width: 76px;
  }

  .hideArrows {
    display: none;
  }

  .nextIcon, .prevIcon {
    color: var(--accent-green);
    cursor: pointer;
    font-size: 2rem;
    margin: 10px 5px;
    z-index: 115;
    padding: 5px;
    border-radius: 3px;
    transition: 50ms;
    border: 1px solid var(--accent-green);
  }

  .disabled {
    filter: brightness(50%);
    pointer-events: none
  }

  @media screen and (min-width: 1150px) {
    .hideArrows {
      position: fixed;
      z-index: 110;
      top: 90vh;
      left: calc(100vw - 100px);
      display: block;
    }
  }

  @media screen and (min-width: 900px) {

    .furnitureModalBody {
      display: flex;
      width: 90vw;
      height: 100vh;
      left: 5vw;
      background: none;
      border-radius: 10px;
    }

    .furnitureImagesContainer {
      flex-direction: row;
      width: 100%;
      align-items: flex-start;
    }

    .furnitureBeforeImageContainer {
      display: flex;
      flex-direction: column;
      width: 40%;
      margin: 0 10px;
    }

    .furnitureAfterImageContainer {
      display: flex;
      flex-direction: column;
      width: 60%;
      margin: 0 10px;
    }

    .beforePhoto {
      max-height: 80vh;
      max-width: 100%;
    }

    .afterPhoto {
      max-height: 80vh;
      max-width: 100%;
    }
  }

  @media screen and (min-width: 650px) {
    .projectBody {
      font-size: 1.3rem;
    }

    .filmModalBody {
      display: flex;
      padding: 30px;
      top: 20px;
      left: 15vw;
      width: 70vw;
      border-radius: 10px;
      height: fit-content;
      background: none;
    }

    .videoElement {
      border: 2px solid black;
    }

    .photosModalBody {
      display: flex;
      width: 90vw;
      height: 100vh;
      left: 5vw;
      align-items: center;
      background: none;
      border-radius: 10px;
    }

    .closeIconMobile {
      display: none;
    }

    .photoElement {
      max-height: 80vh;
      max-width: 90vw;
    }

    .arrows {
      position: fixed;
      z-index: 110;
      top: 90vh;
      left: calc(100vw - 100px);
    }

    .nextIconDesktop, .prevIconDesktop {
      display: inherit;
      font-size: 2rem;
      margin: 10px;
      z-index: 115;
      cursor: pointer;
      color: var(--accent-green);
      height: fit-content;
      width: 25px;
      transition: 100ms;
    }

    .nextIcon:hover, .prevIcon:hover {
      background:var(--accent-green);
      color: var(--background);
    }

  }

</style>