<template>
  <div class="projectContainer" @click="emitSelected" >
    <div class="bkgImage"  :style="{ 'background-image': 'url(' + require(`/src/assets/film/${project.thumbnail}`) + ')'}">
      <div class="projectTitle">{{project.title}}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'FilmTile',
  props: ['project', 'index'],
  methods: {
    emitSelected() {
      this.$emit('selected', 'film', this.index)
    }
  },
  data() {
    return {
      showDetails: false
    }
  }
}
</script>

<style scoped>

  .projectContainer {
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 300ms;
    background-size: 300px;
    background-position: center center;
    background-repeat: no-repeat;
    min-height: 150px;
  }

  .bkgImage {
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 300ms;
    background-size: 300px;
    background-position: center center;
    background-repeat: no-repeat;
    height: 150px;
    width: 100%;
    max-width: 300px;
  }

  .projectContainer:hover {
    cursor: pointer;
    transform: scale(1.05);
  }

  .normal {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
  }

  .widescreen {
    position: relative;
    padding-bottom: 42.61%;
    height: 0;
  }

  .videoElement {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .detailsContainer {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
  }

  .projectTitle {
    color: var(--white-text);
    font-weight: 800;
    font-size: 1.5rem;
    text-shadow: 3px 3px 10px black;
    text-align: center;
  }

  .projectBody {
    color: white;
    margin: 20px 0;
    font-size: 1.3rem;
    line-height: 1.3;
  }

  @media screen and (min-width: 900px) {
    .projectContainer {
      flex-direction: row;
      min-height: 150px;
    }

    .projectTitle {
      font-size: 2rem;
    }

    .detailsContainer {
      padding: 10px 20px 10px 20px;
    }
  }

</style>
