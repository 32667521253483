<template>
  <div id="app">
    <div id="navContainer">
    <img src="../assets/home/goblin.png" id="still" ref="g" @click="burp" alt="logo">
    <img src="../assets/home/goblin.webp" style="display:none;" id="target" alt="logo">
    <!-- <img src="../assets/home/goblinNavbar.png" id="logo" alt="logo"> -->
    <div id="nav">
      <router-link to="/" class="homeButton">austin elwell</router-link>
      <div id='navLinksContainer'>
        <router-link class="navLink" to="/software">software</router-link>
        <router-link class="navLink" to="/projects">projects</router-link>
        <router-link class="navLink" to="/about">about</router-link>
        <router-link class="navLink" to="/contact">contact</router-link>
      </div>
      <div id='miniNavLinksContainer'>
        <router-link to="/software"><font-awesome-icon icon="code" /></router-link>
        <router-link to="/projects"><font-awesome-icon icon="hammer" /></router-link>
        <router-link to="/about"><font-awesome-icon icon="user" /></router-link>
        <router-link to="/contact"><font-awesome-icon icon="envelope" /></router-link>
      </div>
    </div>
    </div>

    <div class="pageContainer">
      <router-view/>
    </div>
  </div>
</template>


<script>
export default {
  name: 'Navbar',
  methods: {
    burp: function() {
      // need to have a hidden element to see what the actual src attribute will be since Vue adds a unique number when compiling
      let renderedElement = document.getElementById('target')
      document.getElementById('still').setAttribute('src', renderedElement.src)
      console.log('%clogo and animation by', 'font-weight: bold; font-size: 20px;color: white;');
      console.log('%cDaley Pedersen', 'font-weight: bold; font-size: 30px;color: white; text-shadow: 3px 3px 0 rgb(226, 177, 230), 5px 5px 0 rgb(225, 145, 230) , 7px 7px 0 rgb(221, 113, 227)');
  }
  }
}
</script>

<style scoped>

body {
  margin: 0;
  padding: 0;
  background-color: var(--background-color);
}

#app {
  font-family: 'Urbanist', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.homeButton {
  display: none;
}

#navContainer {
  display: flex;
  align-items: center;
  width: 100%;
}

#still, #target {
  height: 100px;
  width: 100px;
  margin-left: 30px;
  margin-bottom: 20px;
  margin-right: -40px;
}

#nav {
  /* border: double 3px transparent; */
  /* background-image: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%); */
  /* background-image: linear-gradient(130deg, #bbc39b, #bbc39b); */
  background: var(--accent-green);
  background-origin: border-box;
  background-clip: content-box, border-box;
  width: 100%;
  margin: 30px;
  height: 70px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

#nav a {
  font-size: 2rem;
  font-weight: 200;
  color: black;
  text-decoration: none;
}

#nav a.router-link-active {
  color: white;
}

#navLinksContainer {
  display: none;
}

.navLink {
  transition: 200ms;
}

.navLink:hover {
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

#miniNavLinksContainer {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

@media screen and (min-width: 650px) {
  .homeButton {
    display: flex;
    padding-left: 25px;
    font-size: 2rem;
    font-weight:500 !important;
    color: var(--background-color) !important;
    margin-right: 75px;
    white-space: nowrap;
  }

  #navLinksContainer, #miniNavLinksContainer {
    margin-left: auto;
  }

  #miniNavLinksContainer {
    justify-content: right;
  }

  #navLinksContainer a {
    margin: 0 50px 0 auto;
  }

  #miniNavLinksContainer a {
    margin: 0 50px 0 auto;
  }
}

@media screen and (min-width: 900px) {
  #miniNavLinksContainer {
    display: none;
  }
  #navLinksContainer {
    display: flex;
  }
}

</style>

