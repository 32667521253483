import { render, staticRenderFns } from "./SoftwareTile.vue?vue&type=template&id=f7371872&scoped=true"
import script from "./SoftwareTile.vue?vue&type=script&lang=js"
export * from "./SoftwareTile.vue?vue&type=script&lang=js"
import style0 from "./SoftwareTile.vue?vue&type=style&index=0&id=f7371872&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f7371872",
  null
  
)

export default component.exports